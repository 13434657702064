<template>
  <v-dialog
    v-model="bank"
    persistent
    max-width="610px"
    scrollable
    content-class="content-scroll"
  >
    <v-card tile>
      <v-card-title class="ma-0 d-flex flex-wrap justify-space-between">
        <span 
          class="font-weight-bold primary--text text-wrap"
          :class="{'text-h4': $vuetify.breakpoint.smAndUp, 'text-h6': $vuetify.breakpoint.xsOnly,}"
        >
          Gestión de Motivo de no cobros y no ventas
        </span>
        <v-btn icon plain @click="cerrar()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-overlay
        :value="loadingMot || loading"
        color="white"
        absolute
        opacity=".8"
      >
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
        ></v-progress-circular>
      </v-overlay>
      <v-card-text>    
        <validation-observer ref="ADMIN_MOTIVO_FORM" tag="div">   
          <v-row>
            <v-col cols="12" class="pt-2 pb-0">
              <label-form text="Nombre" required />
              <validation-provider name="Nombre" vid="motivoInfo.nombre" rules="required" v-slot="{ errors }">
                <v-text-field
                  :value="data.nombre"
                  v-model="motivoInfo.nombre"
                  outlined
                  dense
                  :error-messages="errors[0]"
                />
              </validation-provider>
            </v-col>           
          </v-row>       
          <v-row>
            <v-col cols="12" class="mb-0 py-0">
              <v-list
                three-line
                class="pa-0 ma-0"
              >
                <v-list-item class="pa-0 ma-0">
                  <v-list-item-action class="">
                    <v-switch
                      v-model="motivoInfo.status"
                      color="secondary"
                      hide-details
                    ></v-switch>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="display font-weight-bold pb-0">Habilitar Motivo</v-list-item-title>
                    <v-list-item-subtitle class="pt-0">Opción para habilitar o deshabilitar un Motivo.</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </validation-observer>        
      </v-card-text>
      <v-card-actions class="d-flex justify-end mb-3">
        <v-btn
          tile
          depressed
          color="blue-grey lighten-5"
          class="px-8 mx-2"
          :small="$vuetify.breakpoint.mobile"
          @click="cerrar()"
        >
          Cancelar
        </v-btn>
        <v-btn
          tile
          depressed
          color="primary"
          class="px-8"
          :small="$vuetify.breakpoint.mobile"
          @click="actionBanco"
        >
          <v-icon dark left>mdi-pencil</v-icon>
          {{action === 'editar' ? 'Actualizar' : 'Crear Motivo'}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
const dataDefault = () => ({
  nombre: '',  
  status: true,
});
export default {
  name:'ModalMotivo',
  props:{
    value: Boolean,
    data:{
      type: Object,
      default: () => ({}),
    },
    loading:{
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      default: 'editar',
    },
  },
  data() {
    return {
      bank: this.value,
      loadingMot: false,
      motivoInfo: dataDefault(),     
    }
  },
  watch: {
    bank (val, oldVal) {
      if (val === oldVal) return

      this.$emit('input', val)
    },
    value (val, oldVal) {
      if (val === oldVal) return

      this.bank = val
    },
    data(val) {
      if(Object.values(val).length > 0) {
        this.motivoInfo = {...val }
      }
      else
        this.motivoInfo = dataDefault();     
    }
  },
  methods: {
    cerrar() {
      this.bank = false;
      this.motivoInfo = dataDefault();
      this.$refs.ADMIN_MOTIVO_FORM.reset();
    },
    async actionBanco() {
      const valid = await this.$refs.ADMIN_MOTIVO_FORM.validate();
      if(valid) {
        try {
          this.loadingMot = true;         
          const { data } = await this.$api({
            method: this.action === 'crear' ? 'post' : 'put',
            url: this.action === 'crear' ? 'administracion/motivos' : 'administracion/motivos/' + this.motivoInfo.id,
            data: this.motivoInfo,
          });
          this.cerrar();
          this.$emit('procesado');
          this.$root.$showAlert(data.message, 'success');
        } catch (error) {
            this.$root.$showAlert(
              'Lo sentimos, hubo un error al intentar realizar esta acción en el Servidor.',
              'error'
            );
        } finally {
          this.loadingMot = false;
        }
      }
    },
  },
}
</script>
